<template>
  <div class="batch" style=" height: calc(100vh - 90px);">
    <div class="list">
      <div class="head">
        <p v-for="(item,index) in thead" :key="index">
          {{ item }}
        </p>
      </div>
      <div style="padding-top: 50px;border-bottom: 1px solid #dddddd;">
        <div v-for="(item,i) in dataList" :key="i" style="text-align:center;" class="cont">
          <p>{{ item.batchId }}</p>
          <p>{{ item.gmtExpired | date }}</p>
          <p>{{ item.productCount }}</p>
          <p>{{ item.productUnit }}</p>
          <p>{{ item.inPrice }}</p>
          <p>{{ item.gmtStorage | date }}</p>
        </div>
      </div>
      <van-pagination v-model="listParams.pageNum" :total-items="listParams.totalPage" :items-per-page="listParams.pageSize"
                      @change="getList"/>
      <div class="back">
        <van-button @click="backPage" type="primary" size="small">返回</van-button> 
      </div>
                     
    </div>
  </div>
</template>

<script>
import api from '../api/api'
export default {
  name: "batch",
  data() {
    return {
      listParams: {
        pageNum: 1,
        pageSize: 10,
        storageNo: "",
        totalPage: 0
      },
      dataList: [],
      thead: ['批次', '有效时间', '库存数量', '单位', '入库价', '入库时间']
    }
  },
  created() {
    if(api.getCookie('token')){
      this.$toast('请先登录')
      this.$router.push('/login')
    }else {
      this.getList()
    }
  },
  activated(){
     if(api.getCookie('token')){
      this.$toast('请先登录')
      this.$router.push('/login')
    }else {
      this.getList()
    }
  },
  methods: {
    backPage(){
      // this.$router.go(-1)
      window.history.back()
    },
    getList() {
      this.listParams.storageNo = this.$route.query.storageNo
      api.getBatchList(this.listParams).then((res) => {
            if (res.data.code === '0000') {
              //this.$notify({type: 'success', message: '获取成功'});
              this.dataList = res.data.data.details.list
              this.listParams.totalPage = res.data.data.details.totalPage
            } else {
              this.$notify({type: 'danger', message: res.data.msg});
            }

          }
      )
    },
  },
  filters: {
    date: function (val) {
      return val.split(' ')[0]
    }
  }
}
</script>

<style scoped>
.back{
    width: 80%;
    margin: 20px;
    text-align: right;
}
p {
  margin: 0;
}

.batch {
  width: 100%;
  padding-top: 5px;
}

.head {
  width: 96%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  text-align: center;
  top: 5px;
  left: 2%;
  font-size: 14px;
  border: 1px solid #dddddd;
}

.cont {
  width: 96%;
  margin-left: 2%;
  border: 1px solid #dddddd;
  border-top: 0 none;
  height: 50px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  font-size: 12px;
}

.head p:first-child, .cont p:first-child {
  width: 60px;
  height: 100%;
  line-height: 50px;
  border-right: 1px solid #dddddd;
}

.head p:nth-child(2), .cont p:nth-child(2) {
  width: 100px;
  height: 100%;
  line-height: 50px;
  border-right: 1px solid #dddddd;

}

.head p:nth-child(3), .cont p:nth-child(3) {
  width: 80px;
  height: 100%;
  line-height: 50px;
  border-right: 1px solid #dddddd;
}

.head p:nth-child(4), .cont p:nth-child(4) {
  width: 60px;
  height: 100%;
  line-height: 50px;
  border-right: 1px solid #dddddd;
}

.head p:nth-child(5), .cont p:nth-child(5) {
  width: 70px;
  height: 100%;
  line-height: 50px;
  border-right: 1px solid #dddddd;
}

.head p:nth-child(6), .cont p:nth-child(6) {
  width: 100px;
  height: 100%;
  line-height: 50px;
}

.van-pagination {
  width: 100%;
  position: fixed;
  bottom: 50px;
}
</style>
