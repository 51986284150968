import axios from 'axios'


axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
if (localStorage.getItem('token')) {
  axios.defaults.headers.Authorization = localStorage.getItem('token')
}

export default {
  //设置cookie
  setCookie(name, value) {
    document.cookie = name + "=" + escape(value) + "; path=/";
  },
  //获取cookie
  getCookie(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr === document.cookie.match(reg)) {
      return unescape(arr[2]);
    } else {
      return null;
    }
  },
    //库存接口
    getProductList(data) {
        return axios.post('/api/v1/coob/storage/page', data)
    },
    //统计接口
    statisticsList(data) {
        return axios.post('/api/v1/coob/h5/summary/history', data)
    },
    // 批次接口
    getBatchList(data) {
        return axios.post('/api/v1/coob/storage/batch/page', data)
    },
    // 发送验证码
    getVcode(data) {
        return axios.post('/api/v1/coob-open/sms/send', data)
    },
    // 批次接口
    codeLogin(data) {
        return axios.post('/api/v1/coob-open/sms/login', data)
    },

}
