import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import Router from 'vue-router'
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(Router);
Vue.config.productionTip = false
import router from './router'
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
