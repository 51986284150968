<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/logo.png">
      <p>欢迎使用Coob敏捷电商</p>
    </div>
    <div class="form">
      <van-field v-model="phone" type="number"  placeholder="请输入手机号"/>
      <van-field v-model="vcode" type="number" placeholder="请输入验证码">
        <template #button>
          <van-button size="small" :type="isSend === true ? '' :'info'" :disabled="isSend === true" @click="send" round>{{ codeText }}</van-button>
        </template>
      </van-field>
      <van-button round type="info" style="width: 90%;margin-left: 5%;margin-top: 20px;" @click="login">登录</van-button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import api from "../api/api";
export default {
  name:'login',
  data() {
    return {
      phone:'',
      vcode:'',
      isSend:false,
      codeText:'发送验证码'
    }
  },
  created() {
    const token = location.href.split('=')[1]
    if (token && token !== '') {
      api.setCookie('token', token)
      localStorage.setItem('token', token)
      axios.defaults.headers['Authorization'] = token
      this.$router.push(`/goods`)
    }
  },
  methods:{
    send() {
      let reg = /^1[345678]\d{9}$/
      if(this.phone !== '' && reg.test(this.phone)){
        this.isSend  = true
        this.validateBtn()
        let sendParams = {}
        sendParams.phone = this.phone
        sendParams.sendType = 3
        api.getVcode(sendParams).then((res) => {
          if(res.data.code === '0000'){
            this.$toast('发送成功')
          }else {
            this.$toast(res.data.msg)
          }
        })
      }else{
        this.$notify({ type: 'danger', message: '手机号不合法' });
      }

    },
    validateBtn(){
      let time = 60;
      let timer = setInterval(() => {
        if(time === 0) {
          clearInterval(timer);
          this.isSend = false;
          this.codeText = "获取验证码";
        } else {
          this.codeText =time + '秒后重试';
          this.isSend = true;
          time--
        }
      },1000)
    },
    login() {
      let loginParams = {}
      loginParams.phone = this.phone
      loginParams.smsCode = this.vcode
      api.codeLogin(loginParams).then((res) => {
        if(res.data.code === '0000') {
          console.log(res.data)
          this.$toast.success('登录成功');
          api.setCookie('token',res.data.data.token)
          localStorage.setItem('token',res.data.data.token)
          axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
          this.$router.push('/goods')
        }else {
          this.$toast(res.data.msg)
        }
      })

    }
  }
}
</script>

<style scoped>
.login{
  height: 100vh;
  background: #ffffff;
}
.logo{
  padding-top: 50px;
  text-align: center;
}
p{
  width: 100%;
  font-size: 28px;
}
.form {
  margin-top: 60px;
  box-sizing: border-box;

}
</style>