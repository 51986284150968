<template>
  <div id="app">
    <div class="ProductList">
<keep-alive>
      <router-view/>
</keep-alive>
    </div>
    <div class="footer" v-if="$route.path !== '/login' && $route.path !== '/404'">
      <van-tabbar route :placeholder="true">
        <van-tabbar-item replace to="/goods" icon="search">库存</van-tabbar-item>
        <van-tabbar-item replace to="/statistics" icon="home-o">统计</van-tabbar-item>
         <van-tabbar-item replace to="/memorandum" icon="todo-list-o">备忘录</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '@/api/api'
export default {
  name: 'App',
  created() {
    if(api.getCookie('token')) {
      axios.defaults.headers.common['Authorization'] = api.getCookie('token')
    }
  },
  data() {
    return {
      searchKey: ''
    }
  }
}
</script>

<style>
html, body {
  background-color: #ededed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  width: 100%;
}
</style>
