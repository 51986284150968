<template>
  <div class="item">
    <div class="list">
      <div v-for="(item,index) in statisticsList" :key="index" class="items">
        <div class="title">{{ item.gmtCurrent | date }} 数据统计</div>
        <div class="number">
          <div>
            <span class="w1">销售额：</span>
            <span>{{ item.sales }} 元</span>
          </div>
          <div>
            <span class="w1">毛利润：</span>
            <span>{{ item.grossProfit }} 元</span>
          </div>
          <div>
            <span class="w1">历史排名：</span>
            <span>第 {{ item.salesRank }} 位</span>
          </div>
          <div>
            <span class="w1">订单数量：</span>
            <span>共 {{ item.orderCount }} 个</span>
          </div>
          <div>
            <span class="w1">货品数量：</span>
            <span>共 {{ item.orderProductCount }} 单位</span>
          </div>
          <div>
            <span class="w1">仓库货值：</span>
            <span>{{ item.storageValue }} 元</span>
          </div>
          <div>
            <span class="w1">入库货值：</span>
            <span>{{ item.todayValue }} 元</span>
          </div>
          <div>
            <span class="w1">入库数量：</span>
            <span>{{ item.storageCount }} 单位</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productItem",
  props: ['statisticsList'],
  data() {
    return {}
  },
  filters: {
    date: function (val) {
      return val.split(' ')[0]
    }
  }
}
</script>

<style scoped>
.items {
  width: 92%;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;

}

.title {
  font-size: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}

.number > div {
  margin-bottom: 8px;
  font-size: 12px;
}

.w1 {
  display: inline-block;
  width: 70px;
}
</style>