<template>
  <div style=" height: calc(100vh - 90px);">
    <div class="search">
      <van-search v-model="productParams.productName" placeholder="请输入商品名称关键字" @input="searchData" shape="round" @clear="productParams.productName = ''"  background="#ededed"/>
    </div>
    <div style='padding-bottom: 90px;'>
      <productItem :goodDetail="dataList"></productItem>
    </div>
    <van-pagination v-model="productParams.pageNum" :total-items="total" :items-per-page="productParams.pageSize"  @change="getList"/>
  </div>
</template>

<script>
import productItem from '../components/productItem'
import api from '../api/api'
import axios from 'axios'
export default {
  name: "goods",
  components:{
    productItem
  },
  data() {
    return {
      searchKey:'',
      productParams:{
        pageNum: 2,
        pageSize: 10,
        productName: "",
      },
      total:0,
      dataList:[]
    }
  },
  created() {
    if(api.getCookie('token')){
      this.$toast('请先登录')
      this.$router.push('/login')
    }else {
      this.getList()
    }
  },
  methods:{
    getList() {
      // this.productParams.productName = this.searchKey
      api.getProductList(this.productParams).then((res) =>{
        if(res.data.code === '0000') {
          this.dataList = res.data.data.storagePage.list
          this.total = res.data.data.storagePage.total
          //this.$notify({ type: 'success', message: '获取成功' });
          this.dataList.forEach((item, index) => {
            if (item.productImage) {
              // 下载图片
              axios.get('/api/v1/coob/common/download/' + item.productImage, { responseType: 'arraybuffer' }).then((res) => {
                var blob = new Blob([res.data], { type: 'png' })
                var imageUrl = (window.URL || window.webkitURL).createObjectURL(blob)
                this.$set(this.dataList[index], 'imgUrl', imageUrl)
              })
            }
          })
        } else if( res.data.msg === '请先登录'){
          console.log('res', res)
          alert(res.data.code)
          this.$notify({ type: 'danger', message: axios.defaults.headers.Authorization });
          // this.$router.push('/login')
        }else {
          this.$notify({ type: 'danger', message: res.data.msg });
        }
      }
      )},
    searchData() {
      // this.productParams.productName = this.searchKey
      this.productParams.pageNum = 1
      this.getList()
    }
  }
}
</script>

<style scoped>
.van-pagination{
  width: 100%;
  position: fixed;
  bottom: 50px;
}

</style>
