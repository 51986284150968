<template>
  <div class="item" >
    <div class="list">
      <div v-for="(item,index) in goodDetail" :key="index" class="items">
          <div class="title">{{ item.productName }}</div>
          <div class="content">
            <div class="img">
              <img class="imgItem" :src="item.imgUrl" v-if='item.productImage'>
              <img v-else class="imgItem" src="../assets/default.jpg">
              <!-- <div style='font-size: 50px!important;'>
                <img class="imgItem" src="../assets/default.jpg">
                <van-icon name="photo-o" style='font-size: 50px!important;'/>
              </div> -->
            </div>
            <div class="number">
              <div>
                <span>库存数量：</span>
                <span>{{ item.productCount }}个</span>
              </div>
              <div>
                <span>最新进货价：</span>
                <span>{{ item.lastInPrice }}元</span>
              </div>
              <div>
                <span>平均进货价：</span>
                <span>{{ item.inAvgPrice }}元</span>
              </div>
              <div>
                <span>库存批次数量：</span>
                <!-- <router-link :to="{ path: 'batch', query: { storageNo: item.storageNo }}" style="text-decoration: underline">{{ item.batchNum }}个</router-link> -->
                {{ item.batchNum }}个
                <van-button class="vanButton" @click="gopage(item.storageNo)" type="info">进入批次</van-button>
              </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "productItem",
  props:['goodDetail'],
  data() {
    return {
    }
  },
  methods:{
    gopage(storageNo){
      this.$router.push({
        path: 'batch', query: { storageNo}
      })
    }
  }
}
</script>

<style scoped>
.vanButton{
  display: inline-block;
  width: 57px;
  padding: 0;
  height: 30px;
}
.items{
  width: 92%;
  /* height: 160px; */
  margin: 20px auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;

}
.title{
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #666666;
}
.content {
  display: flex;
  font-size: 13px;
}
.img{
  height: 100%;
  margin-top: 18px;
  margin-right: 20px;
  margin-left: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background: pink;
}
.imgItem{
  width: 80px;
  height: 80px;
}
.number > div{
  margin-bottom: 6px;
  font-size: 1.4em;
}
 span {
  font-size: 10px;
}
</style>