<template>
  <div style="height: calc(100vh - 90px); overflow-y: auto">
    <div class="mesage">
      <van-field
        v-model="remarkText"
        rows="2"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="请输入内容"
        show-word-limit
      />
      <van-button class="addMemo" size="small" @click="addMsg" type="info"
        >添加备忘录</van-button
      >
    </div>
    <div>
      <van-cell-group
        class="cellGroup"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <van-cell title="内容" :value="item.content" />
        <van-cell title="最后编辑人" :value="item.lastUpdater" />
        <van-cell title="添加时间" :value="item.gmtCreated" />
        <van-cell title="上次编辑时间" :value="item.gmtModified" />
        <van-cell title="完成时间" :value="item.gmtDeal ? item.gmtDeal: '--'" />
        <van-cell title="操作">
          <template #right-icon>
            <van-button
              size="mini"
              v-if="item.status == 1"
              @click="addNote(item)"
              type="primary"
              >完成</van-button
            >
            <van-button size="mini" v-else color="#ccc">已完成</van-button>
            <van-button size="mini" @click="deleteRemark(item)" type="warning"
              >删除</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div>
      <van-pagination
        v-model="listParams.pageNum"
        :total-items="totalPage"
        :items-per-page="listParams.pageSize"
        @change="getRemark"
      />
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import remark from "../api/CbNoteController";
import { Toast, Dialog } from "vant";
export default {
  name: "data",
  components: {},
  data() {
    return {
      tableData: [],
      remarkText: "",
      listParams: {
        pageNum: 1,
        pageSize: 5,
      },
      dataList: [],
      totalPage: 0,
    };
  },
  created() {
    if (api.getCookie("token")) {
      this.$toast("请先登录");
      this.$router.push("/login");
    } else {
      this.getRemark();
    }
  },
  methods: {
    addMemo() {},
    // 备忘录完成方法
    addNote(row) {
      Dialog.confirm({ title: "", message: "是否确认完成？" }).then(() => {
        remark.doneRemark(row.remarkNo).then((res) => {
          if (res.data.code === "0000") {
            Toast.success("操作成功");
            this.getRemark();
          } else {
            Toast.fail("操作失败");
          }
        });
      });
    },
    // 新建备忘录
    addMsg() {
      if (!this.remarkText.trim()) {
        Toast.fail("请输入内容");
        return;
      }
      remark
        .createRemark({
          content: this.remarkText,
        })
        .then((res) => {
          if (res.data.code === "0000") {
            Toast.success("添加成功");
            this.getRemark();
          } else {
            Toast.fail(res.data.msg);
          }
        });

      this.remarkText = "";
    },
    // 获取备忘录列表接口
    getRemark() {
      remark
        .remarkList({
          pageNum: this.listParams.pageNum,
          pageSize: this.listParams.pageSize,
        })
        .then((res) => {
          console.log(res, "res");
          if (res.data.code === "0000") {
            this.tableData = res.data.data.list;
            console.log(this.tableData, "this.tableData");
            this.totalPage = res.data.data.total;
          } else {
            console.log(res.data.msg);
            Toast.fail(res.data.msg);
          }
        });
    },

    // 删除
    deleteRemark(item) {
      
        Dialog.confirm({ title: "", message: "是否确认删除？" }).then(() => {
          remark.deleteNote(item.remarkNo).then((res) => {
            if (res.data.code === "0000") {
              Toast.success("该备忘录记录已删除");
              this.getRemark();
              // this.getFindKindAndNum()
            } else {
              Toast.fail(res.data.msg);
            }
          });
        })
        .catch(() => {
          Toast.fail("已取消删除");
        });
    },
    getList() {
      api.statisticsList(this.listParams).then((res) => {
        if (res.data.code === "0000") {
          this.totalPage = res.data.data.totalPage;
          this.dataList = res.data.data.list;
          //this.$notify({type: 'success', message: '获取成功'});
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.van-pagination {
  width: 100%;
  position: fixed;
  bottom: 50px;
}
.mesage {
  padding: 10px 10px 0;
  text-align: right;
}
.addMemo {
  margin: 10px;
}
.cellGroup {
  margin-bottom: 10px;
}
</style>
