
import Router from 'vue-router'
import goods from '@/views/goods'
import login from '@/views/login'
import statistics from '@/views/statistics'
import memorandum from '@/views/memorandum'
import batch from '@/views/batch'
import NotFound from '@/views/NotFound'
let defaultRouter = [
    { path: '/', redirect: '/goods', hidden: true,},
    {
        path: '/login',
        component: login,
        name: '登录',
        alone: true,
    },
    {
        path: '/goods',
        component: goods,
        name: '库存',
        alone: true,
    },
    {
        path: '/statistics',
        name: '统计',
        component: statistics,
        alone: true,
    },
    {
        path: '/memorandum',
        name: '备忘录',
        component: memorandum,
        alone: true,
    },
    {
        path: '/batch',
        name: '批次',
        component: batch,
        alone: true,
    },
    {
        path: '/404',
        component: NotFound,
        name: '404',
        hidden: true,

    },
    {
        path: '*', // 页面不存在的情况下会跳到404页面
        redirect: '/404',
        name: 'notFound',
        hidden: true
    }
]
export default new Router({
    routes: defaultRouter
})
export { defaultRouter }