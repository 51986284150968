import axios from 'axios';

export default {
  //备忘录列表
  remarkList(data){
    return axios.post("/api/v1/coob/remark/page",data);
  },

  // 完成备忘录
  doneRemark(remarkNo) {
    return axios.put('/api/v1/coob/remark/check/' + remarkNo)
  },

   //备忘录新建
   createRemark(data){
    return axios.post('/api/v1/coob/remark/create',data);
  },
  //删除备忘录
  deleteNote(remarkNo){
    return axios.delete("/api/v1/coob/remark/delete/"+ remarkNo);
  }
}