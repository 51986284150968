<template>
  <div style="height: calc(100vh - 90px);overflow-y: auto">
    <div>
      <statItem :statisticsList="dataList"></statItem>
      <van-pagination v-model="listParams.pageNum" :total-items="totalPage" :items-per-page="listParams.pageSize" @change="getList"/>
    </div>
  </div>
</template>

<script>
import api from '../api/api'
import statItem from '../components/statItem'

export default {
  name: "data",
  components: {
    statItem
  },
  data() {
    return {
      listParams: {
        pageNum: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0
    }
  },
  created() {
    if(api.getCookie('token')){
      this.$toast('请先登录')
      this.$router.push('/login')
    }else {
      this.getList()
    }
  },
  methods: {
    getList() {
      api.statisticsList(this.listParams).then((res) => {
            if (res.data.code === '0000') {
              this.totalPage = res.data.data.totalPage
              this.dataList = res.data.data.list
              //this.$notify({type: 'success', message: '获取成功'});
            }else {
              this.$notify({type: 'danger', message: res.data.msg});
            }

          }
      )
    }
  }
}
</script>

<style scoped>
.van-pagination{
  width: 100%;
  position: fixed;
  bottom: 50px;
}

</style>
