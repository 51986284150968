<template>
  <div>
    <van-empty image="error" description="访问的页面不存在" />
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>